'use strict';
var processInclude = require('base/util');
var base = require('pagedesigner/pageDesigner');

base = base || {};
base.methods = base.methods || {};

base.methods.klaviyoShowAlert = function (form, message, isSuccess) {
    $('.email-signup-alert').remove();
    form.prepend('<div class="email-signup-alert alert alert-' + (isSuccess ? 'success' : 'danger') + '">' + message + '</div>');
    setTimeout(function() {
        $('.email-signup-alert').fadeOut('slow', function() {
            $(this).remove();
        });
    }, 5000);
};

// triggered by pagedesigner/pageDesigner.js via main.js
base.klaviyo = function () {
    $('.page-designer-klaviyo-form').on('submit', function (e) {
        e.preventDefault();
        var form = $(this);
        var button = form.find('button[type="submit"]');
        var url = form.attr('action');
        var emailInput = form.find('input[name="e"]');
        var listID = form.find('input[name="listID"]').val();
        var successMsg = form.find('input[name="successMsg"]').val();
        var failMsg = form.find('input[name="failMsg"]').val();
        var csrfToken = form.find('input[name="csrf_token"]').val();

        $.spinner().start();
        button.attr('disabled', true);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                email: emailInput.val(),
                listID: listID,
                successMsg: successMsg,
                failMsg: failMsg,
                csrf_token: csrfToken
            },
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    emailInput.val('');
                    module.exports.methods.klaviyoShowAlert(form, data.msg, true);

                    // If the user successfully subscribes, let's link their email to their Klaviyo ID
                    if (data.subscribedEmail) {
                        try {
                            klaviyo.identify({ 'email' : data.subscribedEmail }).then(() => { klaviyoJS.identifiedUser = true; klaviyoJS.exchangeID = klaviyoJS.getExchangeID(); });
                        } catch (e) {
                            console.log('Klaviyo identify error: ' + e);
                        }
                    }
                } else {
                    module.exports.methods.klaviyoShowAlert(form, data.msg, false);
                }
                button.attr('disabled', false);
            },
            error: function (err) {
                $.spinner().stop();
                module.exports.methods.klaviyoShowAlert(form, failMsg, false);
                button.attr('disabled', false);
            }
        });
    });
};

module.exports = base;

processInclude(module.exports);
